<template>
  <div>
    <el-menu mode="horizontal" default-active="1">
      <el-menu-item index="1">关于系统</el-menu-item>
    </el-menu>
    <div>
      <p>
        友虹文档中台（简称：SC-FCP）为电子文件提供了便捷共享、安全受控以及扩展服务，可以使文件所有者与文件之间实时交互，从而实现文件共享的可信、可控和可追溯。同时，平台通过结合移动互联、OFD技术来确保共享过程的保密性、真实性、完整性、不可否认性，且做到全程可追溯、可监管。可广泛应用于电子文档信息保护领域，实现重要的文档的安全流转和共享。
      </p>
      <h2>产品特性</h2>
      <p>
        友虹文档中台（简称：SC-FCP）主要由文件管理、共享管理、授权策略、访问阅读、授权链、用户组织等几个功能系统构成，并且通过提供开放的SDK服务可以为第三方系统赋能。平台的主要产品特性如下：
      </p>
      <ul>
        <li>
          多种文件存储方式，平台提供两种方式来存储要共享的文件，用户可以通过上传文件到平台中心存储文件，也可以采用分布式存储的方式,用户可以将文件保存在本地（需满足连接互联网），可快速实现文件共享。在中心存储方式中，平台对文件提供多种安全措施，为每个文件进行高级加密，提供国密SM4加密。
        </li>
        <li>
          便捷的共享方式，友虹文档中台的主要特色之一就是实现文件的快速、便捷共享，以共享为目的，以管控为手段。平台提供了提供多途径共享，包括社交媒体方式（微信、QQ、钉钉、支付宝等）、生成二维码共享以及传统的电话、短信、电子邮件等方式共享。
        </li>
        <li>
          共享过程可控，在友虹文档中台中密码和文档内容是可分离的，平台采用动态授权机制，保障文件授权的实时可控。文件所有者可以随时变更别人对文件的使用权限，包括对文件的共享者以及文件的内容访问权限都可随时修改，防止因人员变更导致的机密信息外泄，以及文件传播的失控。
        </li>
        <li>
          共享全程可追溯，平台通过授权链可以查看文件全程的留痕情况，对文件共享的全过程进行管理，可以随时了解：谁看了，什么时间看的，看了那些页面等都可追踪；另外，通过动态水印等技术对共享的文件泄密情况进行溯源，查找泄密的源头。
        </li>
        <li>
          开放SDK服务，平台面向业务应用系统通过开放SDK服务作为访问入口服务，采用RestFull风格的微服务API，封装了SC-FCP面向个人以及第三方系统的操作接口；开放SDK服务提供了用户服务、文档服务、授权服务、数据查询、消息通知等几大类服务接口，并将支持服务级别协议SLA，支持微服务负载均衡，可以最大限度地保障业务连续性和服务质量。
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},

  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang='less' scoped>
@import "../../styles/index.less";
p {
  font-size: @h4;
  text-indent: 2em;
  line-height: 150%;
}
li {
  font-size: @h4;
  line-height: 150%;
  margin: 15px 0px;
}
</style>